import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

const Fellowships = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        {data.markdownRemark.frontmatter.intro ? <ReactMarkdown children={data.markdownRemark.frontmatter.intro} /> : null}
        {data.markdownRemark.frontmatter.openFellowships ? (
          <Accordion allowZeroExpanded>
            {data.markdownRemark.frontmatter.openFellowships.map(fellowship => (
              <AccordionItem key={fellowship.fellowship}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h2>{fellowship.fellowship}</h2>
                    <div className="detail-expand">
                      <small><strong>Deadline:</strong> {moment(fellowship.deadline).format('MMMM DD, YYYY')}</small> <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ReactMarkdown children={fellowship.description} />
                  <ReactMarkdown children={fellowship.howToApply} />
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <h2>{data.markdownRemark.frontmatter.noFellowships}</h2>
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query FellowshipsQuery {
  markdownRemark(frontmatter: {title: {eq: "Fellowships"}}) {
    frontmatter {
      heading
      intro
      openFellowships {
        deadline
        description
        fellowship
        howToApply
      }
      noFellowships
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
    }
  }
}
`

export default Fellowships